import { useEffect } from "react";
import Head from "next/head";
import Layout from "layout/Layout/index.js";
import HomePageSlider from "components/Home/HomePageSlider";
import HomeDomainSearch from "components/Home/HomeDomainSearch";
import HomePackages from "components/Home/HomePackages";
import HomeServices from "components/Home/HomeServices";
import HomeMakdosBlog from "components/Home/HomeMakdosBlog";
import HomeMakdosSupport from "components/Home/HomeMakdosSupport";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useGlobalStore } from "store/GlobalStore";
import { getCurrencyRates, getHomepageProducts, listMakdosBlog } from "services/allServices";
import { useTranslation } from "next-i18next";

export default function Home({ locale, rates, blogList, productList }) {

  const [setCurrencyRates] = useGlobalStore((state) => [state.setCurrencyRates]);
  const { t } = useTranslation("common");
  useEffect(() => {
    setCurrencyRates(rates);
  }, [rates]);
  return (
    <Layout>
      <Head>
        <title>{t('homepageMetaTitle')}</title>
        <meta name="description" content={t('homepageMetaDescription')} />
        <meta property="og:title" content={t('homepageMetaTitle')} key="title" />
        <meta property="og:description" content={t('homepageMetaDescription')} key="description" />
      </Head>
      <HomePageSlider />
      <HomeDomainSearch />
      <HomePackages data={productList} />
      <HomeServices />
      {blogList && blogList != "" &&
        <HomeMakdosBlog data={blogList} />
      }
      <HomeMakdosSupport />
    </Layout>
  );
}

export const getServerSideProps = async ({ locale }) => {

  let rates = {};
  await getCurrencyRates().then(res => {
    rates = res;
  });

  let blogList = {};
  await listMakdosBlog().then(res => {
    blogList = res;
  }).catch(err => {
    blogList = "";
  });

  let productList = {};
  await getHomepageProducts().then(res => {
    const allowedGroupIds = [59, 55, 57];
    productList = res.filter((product) => allowedGroupIds.includes(product.group_id));
  });


  return {
    props: {
      ...await serverSideTranslations(locale, ["nav", "common", "homepage", "slider"]),
      rates,
      blogList,
      productList
    }
  };
};