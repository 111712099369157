import { combineIDContent } from "helpers/helpersFunc";
import  { useEffect, useState } from "react";
import HomePackagesCard from "./HomePackagesCard";
import { useTranslation } from "next-i18next";

function HomePackages({ data }) {
  const { t } = useTranslation("common");
  const [selectedTab, setSelectedTab] = useState(59);
  const [isMonthly, setIsMonthly] = useState(true);
  const [products, setProducts] = useState();


  const onProductChange = async (e) => {
    let dedicatedList = [];
    data.map(async (item) => {
      if (item.group_id === selectedTab) {
        if (item.products && item.products.length > 0) {
          if (selectedTab === 57) {
            dedicatedList.push(await combineIDContent(item.products[0]))
            dedicatedList.push(await combineIDContent(item.products[1]))
            dedicatedList.push(await combineIDContent(item.products[item.products.length - 2]))
            dedicatedList.push(await combineIDContent(item.products[item.products.length - 1]))
            await setProducts(dedicatedList);
          } else {
            setProducts(await combineIDContent(item.products.slice(item.products.length - 4, item.products.length)));
          }
        } else {
          setProducts([]);
        }
      }
    });
  };
  useEffect(() => {
    onProductChange();
  }, [selectedTab]);


  return (
    <div className="mx-6 mt-12 mb-8 text-center Packets lg:mx-0">
      <span className="relative mb-8 text-4xl font-semibold text-after-shadow lg:text-5xl">
        {t("packages")}
      </span>

      <div className="container grid grid-cols-3 mx-auto lg:mt-10">
        <div className="flex col-span-12 items-center my-auto lg:col-span-2">
          <div className="grid grid-cols-4 gap-2 mx-auto my-3 lg:mx-0 lg-my-0">
            {/* <button onClick={() => setSelectedTab(63)}
                    className={`col-span-2  lg:col-span-1 mt-3 md:mt-0  border-2 rounded-3xl py-1.5 px-4 text-sm  border-makdosSecondaryGray duration-300 ${selectedTab === 63 && "bg-makdosYellow"} hover:bg-makdosYellow `}>
              Web Hosting
            </button> */}
            <button onClick={() => setSelectedTab(59)}
              className={`col-span-2  lg:col-span-1 mt-3 md:mt-0  border-2 rounded-3xl font-medium py-2 px-4 text-sm  border-makdosSecondaryGray duration-300 ${selectedTab === 59 && "bg-makdosYellow"} hover:bg-makdosYellow `}>
              {t("windowsCloudServers")}
            </button>
            <button onClick={() => setSelectedTab(55)}
              className={`col-span-2  lg:col-span-1 mt-3 md:mt-0  border-2 rounded-3xl font-medium py-2 px-4 text-sm  border-makdosSecondaryGray duration-300 ${selectedTab === 55 && "bg-makdosYellow"} hover:bg-makdosYellow `}>
              {t("linuxCloudServers")}

            </button>
            <button onClick={() => setSelectedTab(57)}
              className={`col-span-4  lg:col-span-1 mt-3 md:mt-0  border-2 rounded-3xl font-medium py-2 px-4 text-sm  border-makdosSecondaryGray duration-300 ${selectedTab === 57 && "bg-makdosYellow"} hover:bg-makdosYellow `}>
              {t("dedicatedServers")}


            </button>
          </div>
        </div>

        <div className="flex flex-col col-span-12 justify-center items-center ring-0 lg:col-span-1 lg:items-end">
          <div className="flex justify-between items-center p-2 space-x-3 bg-gray-100 rounded-full">
            <div onClick={() => setIsMonthly(true)} className={`py-1 px-3 rounded-full cursor-pointer duration-300  ${isMonthly ? "text-gray-800 bg-white": "text-gray-600 bg-transparent"}`}>
              <p className="text-sm font-semibold">{t('monthly')}</p>
            </div>

            <div onClick={() => setIsMonthly(false)} className={`py-1 px-3 rounded-full cursor-pointer duration-300 ${!isMonthly ? "text-gray-800 bg-white": "text-gray-600 bg-transparent"}`}>
              <p className="text-sm font-semibold">{t('yearly')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container grid grid-cols-4 gap-10 mx-auto mt-8 mb-8 lg:px-4 2xl:px-0">

        {products && products?.map((item, index) => {
          return (
            <div key={index} className="relative col-span-4 lg:col-span-1">
              <HomePackagesCard
                discountAlert={item.product_name === "Paket 5" && t("suggested")}
                data={item}
                isMonthly={isMonthly} />
            </div>
          );
        })
        }
      </div>
    </div>
  );
}

export default HomePackages;
