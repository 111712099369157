import Image from "next/image";
import { useTranslation } from "next-i18next";

function HomeMakdosSupport() {
  const { t } = useTranslation(["common", "homapage"]);
  return (
    <div className="p-4 w-full lg:p-20 makdosSupport bg-makdosSecondaryGray">
      <div className="container grid grid-cols-2 gap-5 mx-auto">
        <div className="col-span-2 supportImage lg:col-span-1">
          <h2 className="mb-5 text-xl">Makdos Bilişim</h2>
          <span
            className="relative mb-8 text-4xl font-bold text-after-shadow lg:text-6xl">7/24 {t("common:support")}</span>
          <Image src="/callCenter.svg" alt="support" title="Support" width="600" height="330" />
        </div>
        <div className="grid grid-cols-2 col-span-2 gap-7 lg:col-span-1">
          <div
            className="z-10 col-span-2 bg-white rounded-md border-b-4 border-transparent shadow-lg transition-all duration-300 cursor-pointer supportCard group hover:border-makdosYellow hover:border-b-4 hover:shadow-2xl">
            <div className="flex items-center px-8 py-4 space-x-5 rounded-sm">
              <svg width="57" height="58" viewBox="0 0 57 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="24.9239" cy="25.125" rx="24.2055" ry="25" fill="#FFDD00" />
                <path
                  d="M16.21 15.125H51.0658C51.5794 15.125 52.0719 15.3357 52.4351 15.7108C52.7982 16.0859 53.0022 16.5946 53.0022 17.125V49.125C53.0022 49.6554 52.7982 50.1641 52.4351 50.5392C52.0719 50.9143 51.5794 51.125 51.0658 51.125H16.21C15.6964 51.125 15.2038 50.9143 14.8407 50.5392C14.4775 50.1641 14.2735 49.6554 14.2735 49.125V17.125C14.2735 16.5946 14.4775 16.0859 14.8407 15.7108C15.2038 15.3357 15.6964 15.125 16.21 15.125ZM49.1294 23.601L33.7773 37.801L18.1464 23.557V47.125H49.1294V23.601ZM19.1359 19.125L33.756 32.449L48.165 19.125H19.1359Z"
                  fill="#192E3A" />
              </svg>
              <div>

                <p className="mt-5">{t("common:E-Mail")}</p>
                <h3 className="mb-4 text-lg font-semibold">info@makdos.com</h3>
                <p className="text-sm text-cardText">{t("homepage:averageResponseTime")}
                  <strong> 2 {t("common:hour")}</strong></p>
              </div>
            </div>
          </div>

          <div
            className="z-10 col-span-2 bg-white rounded-md border-b-4 border-transparent shadow-lg transition-all duration-300 cursor-pointer supportCard group hover:border-makdosYellow hover:border-b-4 hover:shadow-2xl">
            <div className="flex items-center px-8 py-4 space-x-5 rounded-sm">
              <svg width="57" height="58" viewBox="0 0 57 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="24.9239" cy="25.125" rx="24.2055" ry="25" fill="#FFDD00" />
                <path
                  d="M20.9639 39.125L12.3371 46.125V15.125C12.3371 14.5946 12.5411 14.0859 12.9042 13.7108C13.2674 13.3357 13.7599 13.125 14.2735 13.125H43.3201C43.8336 13.125 44.3262 13.3357 44.6893 13.7108C45.0525 14.0859 45.2565 14.5946 45.2565 15.125V39.125H20.9639ZM19.6239 35.125H41.3836V17.125H16.2099V37.895L19.6239 35.125ZM25.8921 43.125H45.7154L49.1294 45.895V25.125H51.0658C51.5794 25.125 52.0719 25.3357 52.4351 25.7108C52.7982 26.0859 53.0022 26.5946 53.0022 27.125V54.125L44.3754 47.125H27.8286C27.315 47.125 26.8224 46.9143 26.4593 46.5392C26.0961 46.1641 25.8921 45.6554 25.8921 45.125V43.125Z"
                  fill="#192E3A" />
              </svg>
              <div>
                <p className="mt-5">{t("common:supportRequest")}</p>
                <h3 className="mb-4 text-lg font-semibold">Ticket</h3>
                <p className="text-sm text-cardText">{t("homepage:averageResponseTime")}
                  <strong> 5 {t("common:minute")}</strong></p>
              </div>
            </div>
          </div>

          <div
            className="z-10 col-span-2 bg-white rounded-md border-b-4 border-transparent shadow-lg transition-all duration-300 cursor-pointer supportCard group hover:border-makdosYellow hover:border-b-4 hover:shadow-2xl">
            <div className="flex items-center px-8 py-4 space-x-5 rounded-sm">
              <svg width="57" height="58" viewBox="0 0 57 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="24.924" cy="25.25" rx="24.2055" ry="25" fill="#FFDD00" />
                <path
                  d="M49.0094 25.2499H51.0659C52.093 25.2499 53.0781 25.6714 53.8044 26.4215C54.5307 27.1716 54.9388 28.1891 54.9388 29.2499V37.2499C54.9388 38.3108 54.5307 39.3282 53.8044 40.0783C53.0781 40.8285 52.093 41.2499 51.0659 41.2499H49.0094C48.5373 45.1165 46.7152 48.6723 43.8851 51.2499C41.055 53.8275 37.4113 55.2499 33.638 55.2499V51.2499C36.7194 51.2499 39.6747 49.9856 41.8536 47.7352C44.0325 45.4848 45.2566 42.4325 45.2566 39.2499V27.2499C45.2566 24.0673 44.0325 21.0151 41.8536 18.7646C39.6747 16.5142 36.7194 15.2499 33.638 15.2499C30.5565 15.2499 27.6013 16.5142 25.4224 18.7646C23.2434 21.0151 22.0193 24.0673 22.0193 27.2499V41.2499H16.21C15.1829 41.2499 14.1978 40.8285 13.4715 40.0783C12.7452 39.3282 12.3372 38.3108 12.3372 37.2499V29.2499C12.3372 28.1891 12.7452 27.1716 13.4715 26.4215C14.1978 25.6714 15.1829 25.2499 16.21 25.2499H18.2665C18.7391 21.3837 20.5614 17.8285 23.3914 15.2513C26.2215 12.6741 29.8649 11.2521 33.638 11.2521C37.411 11.2521 41.0544 12.6741 43.8845 15.2513C46.7146 17.8285 48.5368 21.3837 49.0094 25.2499ZM16.21 29.2499V37.2499H18.1465V29.2499H16.21ZM49.1295 29.2499V37.2499H51.0659V29.2499H49.1295ZM25.4275 40.8199L27.4801 37.4279C29.3257 38.622 31.4602 39.2536 33.638 39.2499C35.8157 39.2536 37.9503 38.622 39.7958 37.4279L41.8484 40.8199C39.3877 42.4122 36.5417 43.2545 33.638 43.2499C30.7342 43.2545 27.8882 42.4122 25.4275 40.8199Z"
                  fill="#192E3A" />
              </svg>
              <div>
              <p className="mt-5">{t("common:phoneSupport")}</p>
              <h3 className="mb-4 text-lg font-semibold">(+90) 850 307 77 44</h3>
              <p className="text-sm text-cardText">{t("homepage:appropriateCustomer")} <strong>3</strong></p>
              </div>
             
            </div>
          </div>

        </div>


      </div>
    </div>
  );
}

export default HomeMakdosSupport;
